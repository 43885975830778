import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import * as COLLECTIONS from '../constants/collections';
import * as FUNCTIONS from '../constants/functions';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  signUpRedirectUrl: process.env.REACT_APP_FIREBASE_CONFIRMATION_EMAIL_REDIRECT,
  cloudFunctionsBaseUrl: process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_BASE_URL
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    if (process.env.REACT_APP_USE_LOCAL_FIREBASE) {
      firebase.functions().useFunctionsEmulator('http://localhost:5000');
    }

    this.firestore = firebase.firestore();
    this.auth = firebase.auth();
    this.emailProvider = firebase.auth.EmailAuthProvider;
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.getLookups = firebase.functions().httpsCallable(FUNCTIONS.GET_LOOKUPS);
    this.getAddressAutocomplete = firebase.functions().httpsCallable(FUNCTIONS.GET_ADDRESS_AUTOCOMPLETE);
    this.getAddressDetails = firebase.functions().httpsCallable(FUNCTIONS.GET_ADDRESS_DETAILS);
    this.rateQuote = firebase.functions().httpsCallable(FUNCTIONS.RATE_QUOTE);
    this.repriceQuote = firebase.functions().httpsCallable(FUNCTIONS.REPRICE_QUOTE);
    this.saveQuote = firebase.functions().httpsCallable(FUNCTIONS.SAVE_QUOTE);
    this.sendEmailConfirmation = firebase.functions().httpsCallable(FUNCTIONS.SEND_EMAIL_CONFIRMATION);
    this.getPolicy = firebase.functions().httpsCallable(FUNCTIONS.GET_POLICY);
    this.saveQuotePerkbox = firebase.functions().httpsCallable(FUNCTIONS.SAVE_QUOTE_PERKBOX);
    this.savePolicyMyDentist = firebase.functions().httpsCallable(FUNCTIONS.SAVE_POLICY_MYDENTIST);
    this.claimQuote = firebase.functions().httpsCallable(FUNCTIONS.CLAIM_QUOTE);
    this.generatePostSignupMTA = firebase.functions().httpsCallable(FUNCTIONS.GENERATE_POST_SIGNUP_MTA);
    this.savePolicy = firebase.functions().httpsCallable(FUNCTIONS.SAVE_POLICY);
    this.takeUpPolicy = firebase.functions().httpsCallable(FUNCTIONS.TAKE_UP_POLICY);
    this.saveMTA = firebase.functions().httpsCallable(FUNCTIONS.SAVE_MTA);
    this.rateMTA = firebase.functions().httpsCallable(FUNCTIONS.RATE_MTA);
    this.createInitialMTA = firebase.functions().httpsCallable(FUNCTIONS.CREATE_INITIAL_MTA);
    this.takeUpMTA = firebase.functions().httpsCallable(FUNCTIONS.TAKE_UP_MTA);
    this.isInsyncCustomer = firebase.functions().httpsCallable(FUNCTIONS.IS_INSYNC_CUSTOMER);

    this.createUserAndSendVerifyEmail = firebase.functions().httpsCallable(FUNCTIONS.CREATE_USER);
    this.createMTAPerkbox = firebase.functions().httpsCallable(FUNCTIONS.CREATE_MTA_PERKBOX);
    this.confirmEmail = firebase.functions().httpsCallable(FUNCTIONS.CONFIRM_EMAIL);
    this.claimPolicy = firebase.functions().httpsCallable(FUNCTIONS.CLAIM_POLICY);
    this.checkPolicyClaimStatus = firebase.functions().httpsCallable(FUNCTIONS.CHECKPOLICYCLAIMSTATUS);
    this.checkQuoteClaimStatus = firebase.functions().httpsCallable(FUNCTIONS.CHECKQUOTECLAIMSTATUS);
    this.getUserQuote = firebase.functions().httpsCallable(FUNCTIONS.GET_USER_QUOTE);
    this.updateUserQuote = firebase.functions().httpsCallable(FUNCTIONS.UPDATE_USER_QUOTE);
    this.getUserQuotes = firebase.functions().httpsCallable(FUNCTIONS.GET_USER_QUOTES);
    this.getQuote = firebase.functions().httpsCallable(FUNCTIONS.GET_QUOTE);
    this.getUserPolicy = firebase.functions().httpsCallable(FUNCTIONS.GET_USER_POLICY);
    this.getUserPolicies = firebase.functions().httpsCallable(FUNCTIONS.GET_USER_POLICIES);
    this.paymentIntent = firebase.functions().httpsCallable(FUNCTIONS.PAYMENT_INTENT);
    this.movePolicyToAPI = firebase.functions().httpsCallable(FUNCTIONS.MOVE_POLICY_TO_API);
    this.getVehicleInfo = firebase.functions().httpsCallable(FUNCTIONS.GET_VEHICLE_INFO);
    this.downloadDocument = firebase.functions().httpsCallable(FUNCTIONS.DOWNLOAD_DOCUMENT);
    this.getQuoteDocuments = firebase.functions().httpsCallable(FUNCTIONS.GET_QUOTE_DOCUMENTS);
    this.rateSchemeServe = firebase.functions().httpsCallable(FUNCTIONS.RATE_SCHEME_SERVE);
    this.sendQuoteForReview = firebase.functions().httpsCallable(FUNCTIONS.SEND_QUOTE_FOR_REVIEW);
    this.createInitialQuote = firebase.functions().httpsCallable(FUNCTIONS.CREATE_INITIAL_QUOTE);
    this.getQuoteInfoFromLeadID = firebase.functions().httpsCallable(FUNCTIONS.GET_QUOTE_INFO_FROM_LEAD_ID);
    this.getQuoteInfoFromOpportunityID = firebase
      .functions()
      .httpsCallable(FUNCTIONS.GET_QUOTE_INFO_FROM_OPPORTUNITY_ID);
    this.createInitialLead = firebase.functions().httpsCallable(FUNCTIONS.CREATE_INITIAL_LEAD);
    this.createInitialRenewal = firebase.functions().httpsCallable(FUNCTIONS.CREATE_INITIAL_RENEWAL);
    this.rateRenewal = firebase.functions().httpsCallable(FUNCTIONS.RATE_RENEWAL);
    this.takeUpRenewal = firebase.functions().httpsCallable(FUNCTIONS.TAKE_UP_RENEWAL);
    this.repriceRenewal = firebase.functions().httpsCallable(FUNCTIONS.REPRICE_RENEWAL);
    this.saveFormData = firebase.functions().httpsCallable(FUNCTIONS.SAVE_FORM_DATA);
    this.reportErrorToYoutrack = firebase.functions().httpsCallable(FUNCTIONS.REPORT_ERROR_TO_YOUTRACK);
    this.clearAffinityPartner = firebase.functions().httpsCallable(FUNCTIONS.CLEAR_AFFINITY_PARTNER);
    this.updateMarketingOptions = firebase.functions().httpsCallable(FUNCTIONS.UPDATE_MARKETING_OPTIONS);
    this.updateDocumentConsent = firebase.functions().httpsCallable(FUNCTIONS.UPDATE_DOCUMENT_CONSENT);
    this.getUserSettings = firebase.functions().httpsCallable(FUNCTIONS.GET_USER_SETTINGS);
    this.getPriceCompareQuote = firebase.functions().httpsCallable(FUNCTIONS.GET_PRICE_COMPARE_QUOTE);
    this.getSalesforceUserInfo = firebase.functions().httpsCallable(FUNCTIONS.GET_SALESFORCE_USER);
  }

  doGetLookups = async product => {
    const rsp = await this.getLookups({ product });
    return rsp.data;
  };

  doGetQuote = async (quote, userId) => {
    const rsp = await this.getQuote({ quote, userId });
    return rsp.data;
  };
  doCreateInitialQuote = async (values, scheme, salesforceLeadId, salesforceOpportunityId, facesId = undefined) => {
    const rsp = await this.createInitialQuote({
      values,
      scheme,
      salesforceLeadId,
      salesforceOpportunityId,
      facesId
    });
    return rsp.data;
  };
  doGetQuoteInfoFromLeadID = async request => {
    const rsp = await this.getQuoteInfoFromLeadID(request);
    return rsp.data;
  };
  doGetQuoteInfoFromOpportunityID = async request => {
    const rsp = await this.getQuoteInfoFromOpportunityID(request);
    return rsp.data;
  };

  doGetAddressAutocomplete = async searchTerm => {
    const rsp = await this.getAddressAutocomplete({ searchTerm });
    const data = rsp.data.filter(d => !!d);
    if (data.length === 0) {
      return null;
    }
    return data;
  };

  doGetAddressDetails = async id => {
    const rsp = await this.getAddressDetails({ id });
    return rsp.data;
  };

  doRateQuote = async (request, quoteRef, prevQuote, faces = undefined) => {
    const rsp = await this.rateQuote({ request, quoteRef, prevQuote, faces });
    return JSON.parse(rsp.data);
  };

  doRateSchemeServe = async (request, schemeId) => {
    return await this.rateSchemeServe({ request, schemeId });
  };

  doRepriceQuote = async (request, quoteRef, underwritingResponseId, product, salesforceOpportunityId) => {
    const rsp = await this.repriceQuote({
      request,
      quoteRef,
      underwritingResponseId,
      product,
      salesforceOpportunityId
    });
    return rsp.data;
  };

  doSaveQuote = async (request, id) => {
    const rsp = await this.saveQuote({ request, id });

    return rsp.data;
  };

  doSaveQuotePerkbox = async request => {
    const rsp = await this.saveQuotePerkbox(request);
    return rsp.data;
  };

  doSavePolicyMyDentist = async request => await this.savePolicyMyDentist({ request });

  doSendEmailConfirmation = async request => {
    await this.sendEmailConfirmation(request);
  };

  doClaimQuote = async request => {
    await this.claimQuote(request);
  };

  doSavePolicy = async request => {
    const rsp = await this.savePolicy(request);
    return rsp.data;
  };

  doTakeUpPolicy = async request => {
    const rsp = await this.takeUpPolicy(request);
    return rsp.data;
  };

  doSaveMTA = async request => {
    await this.saveMTA(request);
  };

  doRateMTA = async request => await this.rateMTA(request);

  doCreateInitialMTA = async request => await this.createInitialMTA(request);

  doCreateMTAPerkbox = async request => await this.createMTAPerkbox(request);

  doCreateInitialRenewal = async request => await this.createInitialRenewal(request);

  doRateRenewal = async request => await this.rateRenewal(request);

  doRepriceRenewal = async request => await this.repriceRenewal(request);

  doTakeUpRenewal = async (
    policyReference,
    renewalReference,
    optionId,
    paymentCodes,
    chargeId,
    bankDetails,
    paymentType
  ) =>
    this.takeUpRenewal({
      policyReference,
      renewalReference,
      optionId,
      paymentCodes,
      chargeId,
      bankDetails,
      paymentType
    });

  doTakeUpMTA = async (
    policyReference,
    mtaReference,
    optionId,
    paymentCodes,
    chargeId,
    paymentType,
    faces = undefined
  ) =>
    this.takeUpMTA({
      policyReference,
      mtaReference,
      optionId,
      paymentCodes,
      chargeId,
      paymentType,
      faces
    });

  doGeneratePostSignupMTA = async () => await this.generatePostSignupMTA();

  doSignInWithGoogle = async () => {
    await this.auth.signInWithPopup(this.googleProvider);
  };

  doSignOut = async () => {
    await this.auth.signOut();
  };

  doSignInWithEmailAndPassword = async (email, password) => {
    await this.auth.signInWithEmailAndPassword(email, password);
  };

  doReauthenticateWithCredential = async password => {
    const user = this.auth.currentUser;
    const credential = this.emailProvider.credential(user.email, password);
    await user.reauthenticateWithCredential(credential);
  };

  doGetUserQuotes = async request => {
    const { data } = await this.getUserQuotes(request);
    return data;
  };

  doGetUserPolicies = async request => {
    const { data } = await this.getUserPolicies(request);
    return data;
  };

  doConfirmEmail = async request => await this.confirmEmail(request);

  doGetClaimedUserQuotes = async () => {
    const { data } = await this.getUserQuotes();
    return data;
  };
  doGetUserPolicy = async request => {
    const { data } = await this.getUserPolicy(request);
    return data;
  };
  doGetSalesforceUser = async request => {
    const { data } = await this.getSalesforceUserInfo(request);
    return data;
  };

  doGetUserQuote = async request => {
    const { data } = await this.getUserQuote(request);
    return data;
  };

  doGetPolicy = async policyReference => {
    const { data } = await this.getPolicy({ policyReference });
    return data;
  };

  doClaimPolicy = async request => {
    const { data } = await this.claimPolicy(request);
    return data;
  };

  doCheckPolicyClaimStatus = async request => {
    const { data } = await this.checkPolicyClaimStatus(request);
    return data;
  };

  doCheckQuoteClaimStatus = async request => {
    const { data } = await this.checkQuoteClaimStatus(request);
    return data;
  };

  doSendPasswordResetEmail = async emailAddress => {
    await this.auth.sendPasswordResetEmail(emailAddress);
  };

  doSignUp = async (email, password, service) => {
    const { user } = await this.auth.createUserWithEmailAndPassword(email, password);
    await this.createUserAndSendVerifyEmail({
      user: {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        uid: user.uid
      },
      additionalData: { displayName: '' },
      service
    });
  };

  // TODO: move to cloud function
  doCreateUserDocument = async (user, additionalData) => {
    // If there is no user, let's not do this.
    if (!user) return;

    // Get a reference to the location in the Firestore where the user
    // document may or may not exist.
    const userRef = this.firestore.doc(`${COLLECTIONS.USERS}/${user.uid}`);

    // Go and fetch a document from that location.
    const snapshot = await userRef.get();

    // If there isn't a document for that user. Let's use information
    // that we got from either Google or our sign up form.
    if (!snapshot.exists) {
      const { displayName, email, photoURL } = user;
      const createdAt = new Date();
      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          createdAt,
          ...additionalData
        });
      } catch (error) {
        console.error('Error creating user', console.error);
      }
    }

    // Get the document and return it, since that's what we're
    // likely to want to do next.
    return this.doGetUserDocument(user.uid);
  };

  doGetUserDocument = async uid => {
    if (!uid) return null;

    return this.firestore.collection(COLLECTIONS.USERS).doc(uid);
  };

  doDownloadDocument = async (id, type, encryptedId = undefined) => {
    const { data } = await this.downloadDocument({
      id,
      type,
      encryptedId
    });
    return data;
  };

  doGetQuoteDocuments = async (quote, userId) => {
    const { data } = await this.getQuoteDocuments({ quote, userId });
    return data;
  };

  doIsInsyncCustomer = async id => {
    const { data } = await this.isInsyncCustomer({
      id
    });
    return data;
  };

  doPaymentIntent = async request => this.paymentIntent(request);

  doMovePolicyToAPI = async (values, sessionId) => this.movePolicyToAPI({ values, sessionId });

  doGetVehicleInfo = async values => {
    const { data } = await this.getVehicleInfo(values);
    return data;
  };

  doSendQuoteForReview = async (request, sessionId) => {
    return await this.sendQuoteForReview({ request, sessionId });
  };

  doSaveFormData = async data => {
    const rsp = await this.saveFormData(data);
    return rsp.data;
  };
  doReportErrorToYoutrack = async request => {
    return await this.reportErrorToYoutrack(request);
  };

  doCreateInitialLead = async (values, scheme, salesforceLeadId, salesforceOpportunityId) => {
    const rsp = await this.createInitialLead({
      values,
      scheme,
      salesforceLeadId,
      salesforceOpportunityId
    });
    return rsp.data;
  };
  doClearAffinityPartner = async request => {
    const { data } = await this.clearAffinityPartner(request);
    return data;
  };
  doUpdateMarketingOptions = async request => {
    const { data } = await this.updateMarketingOptions(request);
    return data;
  };
  doUpdateDocumentConsent = async request => {
    const { data } = await this.updateDocumentConsent(request);
    return data;
  };
  doGetUserSettings = async request => {
    const { data } = await this.getUserSettings(request);
    return data;
  };

  doGetPriceCompareQuote = async documentId => {
    const { data } = await this.getPriceCompareQuote({ documentId });
    return data;
  };
}

export default Firebase;
