import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import './scss/index.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './components/Firebase';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { App } from './App';

const firebase = new Firebase();
const queryClient = new QueryClient();
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://958551bc8b5a4650a062b74dcab96657@sentry-insync.coreline.hr/5'
  });
}
ReactDOM.render(
  <FirebaseContext.Provider value={firebase}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
